.container {
  cursor: pointer;
  display: block;
  user-select: none;
  color: #14b2bb;
  transition: all 0.2s ease-out;
}

.container:hover {
  opacity: 0.7;
  color: #14b2bb;
  transition: all 0.2s ease-out;
}

.container:active {
  opacity: 0.5;
  color: #14b2bb;
  transition: all 0.2s ease-out;
}
