.container {
  text-decoration: none;
  transition: opacity 0.2s ease-out;
}

.container:hover {
  opacity: 0.7;
  transition: opacity 0.2s ease-out;
}

.container:focus {
  opacity: 0.5;
  transition: opacity 0.2s ease-out;
}
